<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="activityList">
		<div class="activityList-data">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.activityName" />
				</div>
				<div class="common-row-item">
					<span class="common-row-label">活动ID</span>
					<el-input class="common-row-ipt" placeholder="请输入活动ID" v-model="searchForm.activityId" />
				</div>
				<div class="common-row-item">
					<el-button @click="search" type="primary">查询</el-button>
					<el-button @click="reset">重置</el-button>
				</div>
			</div>
			<el-table :data="tableData" border>
				<el-table-column  prop="id" label="活动ID">
				</el-table-column>
				<el-table-column prop="activityName" label="活动名称">
				</el-table-column>
				<el-table-column width="250px" prop="type" label="活动时间">
					<template slot-scope="scope" v-if="scope.row.starTime&&scope.row.endTime">
						<div class="table-action-date">
							<span>{{`${dateFormat(scope.row.starTime)} 至 ${dateFormat(scope.row.endTime)}`}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="140px" prop="activityStatus" label="活动状态">
					<template v-if="scope.row.activityStatus*1 >= 0" slot-scope="scope">
						<div v-if="scope.row.activityStatus == 2" class="table-action">
							<span @click="actionFn('upload', scope)"
								:class="{'table-action-status_orange': scope.row.workingStatus == 1,'table-action-status_red': scope.row.workingStatus == 2,'table-action-status_green': scope.row.workingStatus == 3}">{{scope.row.workingStatus == 1 ?"工时表待提交":scope.row.workingStatus == 2 ?"工时表超时未提交":"工时表已提交"}}</span>
						</div>
						<div v-else class="table-action">
							<span
								:class="{'table-action-status_black': scope.row.activityStatus != 0,'table-action-status_red': scope.row.activityStatus == 0}">{{scope.row.activityStatus == 0?"未通过":scope.row.activityStatus == 1?"审核中":scope.row.activityStatus == 2?"进行中":"已完成"}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="activityType" label="活动分类">
					<template slot-scope="scope">
						<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
					</template>
				</el-table-column>
				<el-table-column width="220px" prop="organizer" label="组织名称">
				</el-table-column>
				<el-table-column width="200px" prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span @click="actionFn('edit', scope)">编辑</span>
							<el-divider direction="vertical"></el-divider>
							<span @click="actionFn('detail', scope)">详情</span>
							<el-divider direction="vertical"></el-divider>
							<span @click="actionFn('delete', scope)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="common-pageNation">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { listAllactivity, deleteActivity } from "@/api/activity";
export default {
  data() {
    return {
      currentTab: "0",
      totalCount: 0,
      pageIndex: 1,
      pageSize: 10,
      tableData: [],
      searchForm: {},
    };
  },
  mounted() {
    this.getListAllactivity();
  },
  methods: {
    dateFormat(intDate) {
      if (intDate) {
        return intDate.replace(" 00:00:00", "");
      }
    },
    search() {
      this.pageIndex = 1;
      this.getListAllactivity();
    },
    reset() {
      this.searchForm = {};
      this.pageIndex = 1;
      this.getListAllactivity();
    },
    currentChange(val) {
      this.pageIndex = val;
      this.tableData = [];
      this.getListAllactivity();
    },
    // 活动我的全部一般活动列表
    getListAllactivity() {
      let params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      if (this.searchForm.activityId) {
        this.$set(params, "activityId", this.searchForm.activityId);
      }
      if (this.searchForm.activityName) {
        this.$set(params, "activityName", this.searchForm.activityName);
      }
      listAllactivity(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.totalCount = res.page.totalCount * 1;
        }
      });
    },
    actionFn(type, scope) {
      switch (type) {
        case "edit":
          this.$router.push({
            path: `/activityList/edit?activityId=${scope.row.id}`
          });
          break;
        case "detail":
          this.$router.push({
            path: `/activityList/detail?activityId=${scope.row.id}`
          });
          break;
        case "upload":
          if (scope.row.activityStatus == 2 && scope.row.workingStatus == 1) {
            this.$router.push({
              path: `/activityList/detail?activityId=${scope.row.id}`
            });
          }
          break;
        case "delete":
          this.$confirm("确定要删除此活动吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error"
          })
            .then(() => {
              deleteActivity(scope.row.id, { id: scope.row.id }).then(res => {
                if (res.code == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  });
                  this.pageIndex = 1;
                  this.getListAllactivity();
                }
              });
            })
            .catch(() => {});
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  height: 36px;
  border-radius: 0px 2px 2px 0px;
}

/deep/.el-button {
  height: 36px;
}

.activityList {
  .table-action-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #303133;
    }
  }

  .table-action {
    .table-action-status_black {
      color: #303133;
    }

    .table-action-status_green {
      color: #1cabb9;
    }

    .table-action-status_orange {
      color: #f5803e;
    }

    .table-action-status_red {
      color: #e63757;
    }
  }

  .activityList-data {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 20px;

    .common-row {
      margin: 16px 0;

      .common-row-item {
        margin-right: 20px;

        .common-row-ipt {
          flex: 1;
          height: 36px;
          line-height: 36px;
        }
      }
    }

    .table-action {
      .table-action-status1 {
        color: #303133;
      }

      .table-action-status2 {
        color: #f5803e;
      }

      .table-action-status3 {
        color: #e63757;
      }

      .table-action-status4 {
        color: #e63757;
      }
    }
  }
}
</style>
